import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from 'react-redux';

import { Routes, Route } from 'react-router-dom';

import MainWebPageCustomNavbar from "./components/MainWebPageCustomNavbar";
import ConfirmModelUpdateDelete from "./components/ConfirmModelUpdateDelete";

import SelectRecordPopUp from "./components/SelectRecordPopUp";

import Login from './pages/Login';
import SignUp from './pages/SignUp';

// ////////////////////////////////////////////////////////////////////////////////////

import { CRUDContextProvider } from './components/CRUDContext';

import { SearchStudentDetails } from './components/SearchStudentDetails';

import StudentMasterDetails from './pages/StudentMasterDetails';
import StudentMaster from './pages/StudentMaster';

import Brms from './pages/Brms';
import Sfl from './pages/Sfl';
import Lc from './pages/Lc';
import Pa from './pages/Pa';
import Ug from './pages/Ug';
import Ugview from './pages/Ugview';
import Cg from './pages/Cg';
import Cgview from './pages/Cgview';
import Nu from './pages/Nu';
import Nuview from './pages/Nuview';
import Br from './pages/Br';
import Brview from './pages/Brview';
import Nugb from './pages/Nugb';
import Nugbview from './pages/Nugbview';
import Fcg from "./pages/Fcg";
import Ffg from "./pages/Ffg";
import Fcs from "./pages/Fcs";
import Fms from "./pages/Fms";
import Brug from './pages/Brug';
import Brpa from './pages/Brpa';
import Frsbw from './pages/Frsbw';
import Frfomatbw from './pages/Frfomatbw';
import Avt from './pages/Avt';
import Ag from './pages/Ag';
import Agview from './pages/Agview';
import Al from './pages/Al';
import Alview from './pages/Alview';
import User from './pages/Cu';
import Cuview from './pages/Cuview';
import Fm from './pages/Fm';
import Fmview from './pages/Fmview';


import Feecollection from './pages/Fc';
import Studentconveyence from './pages/Sc';
import Feeheadadd from './pages/Sfa';
import Feecon from './pages/Sfc';
import Voucherentry from './pages/Vm';
import Cb from './pages/Cb';
import Sdt from './pages/Sdt';
import Fcpr from './pages/Fcpr';
import DyTab from './pages/DynamicTablePivot';
import Ps from './pages/Ps';
import Psview from './pages/Psview';
import Bwal from './pages/BwaL';
import Bwag from './pages/Bwag';
import DailyCollectionReport from './pages/DailyCollectionReport';
import ExAdm from './pages/AdmOkFromExcel';
import Pspr from './pages/Pspr';
import Fmcopydel from './pages/Fmcopydel';
import Mi from './pages/Mi';
import MenuJoins from "./pages/MenuJoins";
import Sl from "./pages/StudentList";
import FeeDefaulterMonthWise from "./pages/FeeDefaulterMonthWise";
import ExamReportCardPrintView from "./pages/ExamReportCardPrintView";
import FeeDefaulterMonthWiseAll from "./pages/FeeDefaulterMonthWiseAll";
import StudentExamMarks from "./pages/StudentExamMarks";
import ExamTerm from "./pages/ExamTerm";
import Fcview from "./pages/Fcview";
import Vmview from "./pages/Vmview";
import FcprFkg from "./pages/FcprFkg";
import FeeCollLed from "./pages/FeeCollLed";
import Vmkg from "./pages/Vmkg";
import CbAll from "./pages/CbAll";
import AcDayBook from "./pages/AcDayBook";
import VouPrint from "./pages/VouPrint";
import DemoTableForm from "./pages/DemoTableForm";
import AllStudentList from "./pages/AllStudentList";
import AllStudentList_2 from "./pages/AllStudentList_2";

import FeeFineMaster from "./pages/FeeFineMaster";
import MonthWiseSummary from "./pages/MonthWiseSummary";
import ExamGroup from "./pages/ExamGroup";
import EmployeeMaster from "./pages/EmployeeMaster";
import EmployeeMasterView from "./pages/EmployeeMasterView";
import EmployeeMasterBranchWise from "./pages/EmployeeMasterBranchWise";
import SubjectMasterBranchWise from "./pages/SubjectMasterBranchWise";
import SubjectWiseTeacher from "./pages/SubjectWiseTeacher";
import StudentAdmitCardDeskSlip from "./pages/StudentAdmitCardDeskSlip";
import StudentSubjectAssign from "./pages/StudentSubjectAssign";
import StudentFullFeeConcession from "./pages/StudentFullFeeConcession";
import UserGroupPageMasterBranchWise from "./pages/UserGroupPageMasterBranchWise";
import Check1 from "./pages/Check1";
import ChangePassword from "./components/ChangePassword";

import StudentYearEndProcess from "./pages/StudentYearEndProcess";

import StudentTransferMaster from "./pages/StudentTransferMaster";

import ExamReportCardMasterBW from "./pages/ExamReportCardMasterBW";

import SchoolReopenAfterExam from "./pages/SchoolReopenAfterExam";

import Check5 from "./pages/Check5";
import ExamReportCardPrint from "./pages/ExamReportCardPrint";
import TransportMaster from "./pages/TransportMaster";
import ExamType from "./pages/ExamType";
import ExamGrade from "./pages/ExamGrade";
import ExamMarks from "./pages/ExamMarks";
import ExamRemarks from "./pages/ExamRemarks";
import ExamFinalMarks from "./pages/ExamFinalMarks";
import CopyToFinYearBranchWise from "./pages/CopyToFinYearBranchWise";
import store from './components/redux/store';

import BranchWiseDefault from "./pages/BranchWiseDefault";

import Dashboard from "./pages/Dashboard";

import VehicleMaster from "./pages/VehicleMaster";

import VehicleMasterDetails from "./pages/VehicleMasterDetails";

import TransportRoute from "./pages/TransportRoute";

import TransportStoppage from "./pages/TransportStoppage";

import TransportRouteWiseStop from "./pages/TransportRouteWiseStop";

import RTEConcession from "./pages/RTEConcession";

import StudentStrength from "./pages/StudentStrength";

import AllStudentLeftList from "./pages/AllStudentLeftList";

import StStrengthRelCaste from "./pages/StStrengthRelCaste";

import AcLedgerDetails from "./pages/AcLedgerDetails";

import StudentMasterDetailU from "./pages/StudentMasterDetailU";


// ////////////////////////////////////////////////////////////////////////////////////

// Start With persist (Donot delete)

import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react'
let persistor = persistStore(store);

// End With persist (Donot delete)

// ////////////////////////////////////////////////////////////////////////////////////

// axios.defaults.baseURL = "http://localhost:3001/api/";

axios.defaults.baseURL = "/api/";

const App = () => {
  return (
    <>
        <Provider store={store}>

        <PersistGate persistor = {persistor}>

            <ToastContainer />
            <CRUDContextProvider>
            <MainWebPageCustomNavbar />
            <Routes>

                    {/* <Route path='/SignUp' element={<SignUp />}></Route> */}

                    <Route path='/StudentMasterDetailU' element={<StudentMasterDetailU />}></Route>

                    <Route path='/AcLedgerDetails' element={<AcLedgerDetails />}></Route>

                    <Route path='/StStrengthRelCaste' element={<StStrengthRelCaste />}></Route>

                    <Route path='/AllStudentLeftList' element={<AllStudentLeftList />}></Route>

                    <Route path='/StudentStrength' element={<StudentStrength />}></Route>

                    <Route path='/RTEConcession' element={<RTEConcession />}></Route>

                    <Route path='/TransportRouteWiseStop' element={<TransportRouteWiseStop />}></Route>

                    <Route path='/TransportStoppage' element={<TransportStoppage />}></Route>
                    
                    <Route path='/TransportRoute' element={<TransportRoute />}></Route>

                    <Route path='/VehicleMaster' element={<VehicleMaster />}></Route>

                    <Route path='/VehicleMasterDetails' element={<VehicleMasterDetails />}></Route>                    

                    <Route path='/Login' element={<Login />}></Route>
                    
                    <Route path='/ConfirmModelUpdateDelete' element={<ConfirmModelUpdateDelete />}></Route>  

                    <Route path='/Dashboard' element={<Dashboard />}></Route>

                    <Route path='/StudentTransferMaster' element={<StudentTransferMaster />}></Route>                    

                    {/* From old code */}                                    

                    <Route path = '/StudentYearEndProcess' element = {<StudentYearEndProcess />}></Route>                    

                    <Route path = '/SchoolReopenAfterExam' element = {<SchoolReopenAfterExam />}></Route>

                    <Route path = '/BranchWiseDefault' element = {<BranchWiseDefault />}></Route>

                    <Route path = '/CopyToFinYearBranchWise' element = {<CopyToFinYearBranchWise />}></Route>

                    <Route path = '/SelectRecordPopUp' element = {<SelectRecordPopUp />}></Route>                    

                    <Route path = '/ExamFinalMarks' element = {<ExamFinalMarks />}></Route>
                    
                    <Route path = '/ExamRemarks' element = {<ExamRemarks />}></Route>

                    <Route path = '/ExamMarks' element = {<ExamMarks />}></Route>

                    <Route path = '/ExamType' element = {<ExamType />}></Route>                      

                    <Route path = '/ExamTerm' element = {<ExamTerm />}></Route>

                    <Route path = '/TransportMaster' element = {<TransportMaster />}></Route>

                    <Route path = '/ExamReportCardPrint' element = {<ExamReportCardPrint />}></Route>

                    <Route path = '/ExamReportCardPrintView' element = {<ExamReportCardPrintView />}></Route>

                    <Route path = '/ExamReportCardMasterBW' element = {<ExamReportCardMasterBW />}></Route>

                    <Route path = '/UserGroupPageMasterBranchWise' element = {<UserGroupPageMasterBranchWise />}></Route>

                    <Route path = '/StudentFullFeeConcession' element = {<StudentFullFeeConcession />}></Route>

                    <Route path = '/StudentSubjectAssign' element = {<StudentSubjectAssign />}></Route>

                    <Route path = '/StudentExamMarks' element = {<StudentExamMarks />}></Route>

                    <Route path = '/StudentAdmitCardDeskSlip' element = {<StudentAdmitCardDeskSlip />}></Route>

                    <Route path = '/ChangePassword' element = {<ChangePassword />}></Route>

                    <Route path = '/Check1' element = {<Check1 />}></Route>

                    <Route path = '/Check5' element = {<Check5 />}></Route>


                    <Route path = '/SubjectWiseTeacher' element = {<SubjectWiseTeacher />}></Route>

                    <Route path = '/SubjectMasterBranchWise' element = {<SubjectMasterBranchWise />}></Route>

                    <Route path = '/EmployeeMasterBranchWise' element = {<EmployeeMasterBranchWise />}></Route>                  

                    <Route path = '/EmployeeMasterView' element = {<EmployeeMasterView />}></Route>

                    <Route path = '/EmployeeMaster' element = {<EmployeeMaster />}></Route>

                    <Route path = '/ExamGroup' element = {<ExamGroup />}></Route>

                    <Route path = '/ExamGrade' element = {<ExamGrade />}></Route>                  

                    <Route path = '/MonthWiseSummary' element = {<MonthWiseSummary />}></Route>

                    <Route path = '/FeeFineMaster' element = {<FeeFineMaster />}></Route>

                    <Route path = '/AllStudentList' element = {<AllStudentList />}></Route>
                    <Route path = '/AllStudentList_2' element = {<AllStudentList_2 />}></Route>

                    <Route path = '/FeeDefaulterMonthWiseAll' element = {<FeeDefaulterMonthWiseAll/>}></Route>

                    <Route path = '/DemoTableForm' element = {<DemoTableForm/>}></Route>

                    <Route path = '/VouPrint' element = {<VouPrint/>}></Route>

                    <Route path = '/AcDayBook' element = {<AcDayBook/>}></Route>  

                    <Route path = '/CbAll' element = {<CbAll/>}></Route>                  

                    <Route path = '/Vmkg' element = {<Vmkg/>}></Route>

                    <Route path = '/FeeCollLed' element = {<FeeCollLed/>}></Route>

                    <Route path = '/Vmview' element = {<Vmview/>}></Route>                  

                    <Route path = '/sl' element = {<Sl/>}></Route>
                    <Route path = '/FeeDefaulterMonthWise' element = {<FeeDefaulterMonthWise/>}></Route>

                    <Route path = '/FcprFkg' element = {<FcprFkg/>}></Route>

                    <Route path = '/Fcview' element = {<Fcview/>}></Route>

                    <Route path = '/Login' element = {<Login/>}></Route>
                    <Route path = '/login' element = {<Login/>}></Route>
                    <Route path = '/brms' element = {<Brms/>}></Route>
                    <Route path = '/sfl' element = {<Sfl/>}></Route>
                    <Route path = '/lc' element = {<Lc/>}></Route>
                    <Route path = '/pa' element = {<Pa/>}></Route>
                    <Route path = '/ug' element = {<Ug/>}></Route>
                    <Route path = '/ug_view' element = {<Ugview/>}></Route>
                    <Route path = '/cg' element = {<Cg/>}></Route>
                    <Route path = '/cg_view' element = {<Cgview/>}></Route>
                    <Route path = '/nu' element = {<Nu/>}></Route>
                    <Route path = '/nu_view' element = {<Nuview/>}></Route>
                    <Route path = '/br' element = {<Br/>}></Route>
                    <Route path = '/br_view' element = {<Brview/>}></Route>
                    <Route path = '/nugb' element = {<Nugb/>}></Route>                
                    <Route path = '/nugb_view' element = {<Nugbview/>}></Route>
                    <Route path = '/fcg' element = {<Fcg/>}></Route>
                    <Route path = '/ffg' element = {<Ffg/>}></Route>
                    <Route path = '/fcs' element = {<Fcs/>}></Route>
                    <Route path = '/fms' element = {<Fms/>}></Route>
                    <Route path = '/br_ug' element = {<Brug/>}></Route>
                    <Route path = '/br_pa' element = {<Brpa/>}></Route>
                    <Route path = '/frsbw' element = {<Frsbw/>}></Route>
                    <Route path = '/frfomatbw' element = {<Frfomatbw/>}></Route>
                    <Route path = '/avt' element = {<Avt/>}></Route>
                    <Route path = '/ag' element = {<Ag/>}></Route>
                    <Route path = '/ag_view' element = {<Agview/>}></Route>
                    <Route path = '/al' element = {<Al/>}></Route>
                    <Route path = '/al_view' element = {<Alview/>}></Route>
                    <Route path = '/cu' element = {<User/>}></Route>
                    <Route path = '/cu_view' element = {<Cuview/>}></Route>
                    <Route path = '/fm' element = {<Fm/>}></Route>
                    <Route path = '/Fmview' element = {<Fmview/>}></Route>
                    <Route path = '/sm' element = {<StudentMaster/>}></Route>     
                    <Route path = '/StudentMasterDetails' element = {<StudentMasterDetails/>}></Route> 
                    <Route path = '/fc' element = {<Feecollection/>}></Route>
                    <Route path = '/sc' element = {<Studentconveyence/>}></Route>
                    <Route path = '/sfa' element = {<Feeheadadd/>}></Route> 
                    <Route path = '/sfc' element = {<Feecon/>}></Route>
                    <Route path = '/vm' element = {<Voucherentry />}></Route>
                    <Route path = '/cb' element = {<Cb />}></Route>                 
                    <Route path = '/Sdt' element = {<Sdt />}></Route>                                  
                    <Route path = '/Fcpr' element = {<Fcpr />}></Route>

                    <Route path = '/dt' element = {<DyTab />}></Route>
                    <Route path = '/ps' element = {<Ps />}></Route>
                    <Route path = '/psview' element = {<Psview />}></Route>

                    <Route path = '/bwal' element = {<Bwal />}></Route>
                    <Route path = '/bwag' element = {<Bwag />}></Route>

                    <Route path = '/DailyCollectionReport' element = {<DailyCollectionReport />}></Route>

                    <Route path = '/pspr' element = {<Pspr />}></Route>

                    <Route path = '/fmcopy' element = {<Fmcopydel />}></Route>

                    <Route path = '/menuitems' element = {<Mi />}></Route>

                    <Route path = '/menulink' element = {<MenuJoins />}></Route>      

                    <Route path = '/exadm' element = {<ExAdm />}></Route>


            </Routes>
            </CRUDContextProvider>        

        </PersistGate>


        </Provider>
        
    </>
  );
};

export default App;
