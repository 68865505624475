import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { GetFormAuthDetails} from '../components/GlobalFunction';
import CRUDContext from '../components/CRUDContext';
// import DropDownTableComponent from "../components/DropDownTableComponent";

import ExamMarks from './ExamMarks';
import ExamRemarks from './ExamRemarks';
import ExamFinalMarks from './ExamFinalMarks';

import { setTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const StudentExamMarks = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';            
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);    
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxDDStudentSectionData = useSelector((state) => state.DDStudentSectionData);    
    const ReduxDDStStreamData = useSelector((state) => state.DDStStreamData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);  
   
    // define drop downs inputs
    
    const [stream, setstream] = useState(0);
    const [st_class, setst_class] = useState(0);
    const [st_sec, setst_sec] = useState(0);
    
    const [disableShow, setdisableShow] = useState(false);

    const [exam_group_id, setexam_group_id ] = useState(0);
    const [exam_group_idItems, setexam_group_idItems] = useState([]);

    const [headingcolumn, setheadingcolumn] = useState([]);

    const [showRemarksEntry, setShowRemarksEntry] = useState(0);

    const [showNavBar, setshowNavBar] = useState(true);    
        
    // When we will use Multiple selection in Drop Down
    
    // define drop downs items
    
    let v_input_result;

    // define variable for tables entry (master detail form)
    
    const [items, setItems] = useState([]);

    const [exam_term_name, setexam_term_name] = useState(0);
    const [exam_term_nameItems, setexam_term_nameItems] = useState([]);

    const [exam_term_categ, setexam_term_categ] = useState(0);
    const [exam_term_categItems, setexam_term_categItems] = useState([]);
    
    // define standard errors and entry required messages

    // const [errors_message_adm_no, setErrors_message_adm_no] = useState('');
    // const [errors_adm_no, setErrors_adm_no] = useState(false);

    // define form name and navigation details

    // const v_urlShowReportData = '32_url_examgroup_branch_wise_dd_sa_fun';

    const v_urlShowReportData = '';
    const v_urlSaveUpdateDelete = '';
    const v_backToNavigateName = '/Dashboard';
    // const v_findToNavigateName = '/Fcview';
    const v_main_form_heading = 'Student Exam Register !';
    const v_frm_no = 25;

    const {           
        whenStudentNotFoundMessage,
        v_allow_open,       
        v_session_expire_msg,
        v_access_denied_msg,       
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);      

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);                

                // console.log("Update allow_update >> ", result.allow_update)

                if (ReduxGlobalData[0].stem > 0){
                   setstream(ReduxGlobalData[0].stem);
                }                

                setBlankFields();                
                
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();

        setst_class(0);
        setst_sec(0);
        setexam_group_id(0);
        setexam_term_name(0);
        setexam_term_categ(0);
        setShowRemarksEntry(0);

    }, [ReduxGlobalData[0]]);
       
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);
        setdisableSave(false);
        setItems([]);        
        setheadingcolumn([]);

        setshowNavBar(true);
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'st_class') {
            setBlankFields();
                 
            setst_class(value);
            setexam_group_id(0);
            setexam_term_name(0);
            setexam_term_categ(0);
            setShowRemarksEntry(0);

            FillDropDownList(value, stream, exam_term_name, exam_term_categ, exam_group_id);

            return;
        }else if (name === 'st_sec') {

            setShowRemarksEntry(0);
            setBlankFields();

            setst_sec(value);            
            return;
        }else if (name === 'stream') {                  
            
            setBlankFields();            
            setShowRemarksEntry(0);            

            FillDropDownList(st_class, value, exam_term_name, exam_term_categ, exam_group_id);
            
            setstream(value);
            return;
        }else if (name === 'exam_group_id') {            
            
            setBlankFields();

            setexam_term_name(0);
            setexam_term_categ(0);
            setShowRemarksEntry(0);
        
            FillDropDownList(st_class, stream, exam_term_name, exam_term_categ, value);

            setexam_group_id(value);
            return;
        }else if (name === 'exam_term_name'){

            setBlankFields();

            setexam_term_categ(0);
            setShowRemarksEntry(0);
            
            FillDropDownList(st_class, stream, value, exam_term_categ, exam_group_id);

            setexam_term_name(value);
            return;
        }else if (name === 'exam_term_categ'){
            setBlankFields();

            setShowRemarksEntry(0);

            setexam_term_categ(value);
            return;
        }  
    }

    const FillDropDownList = async (p_st_class, p_stream, p_exam_term_name, p_exam_term_categ, p_exam_group_id) => {

        if (parseInt(p_st_class) > 0)
        {            
            const v_token = Cookies.get('token');

            let v_active_status = 1, v_null_parameter = 'NA', v_stream = 0, v_dd_type = '';

            if (ReduxGlobalData[0].stem > 0){
                v_stream = ReduxGlobalData[0].stem;
            }else{
                v_stream = p_stream;
            }            

            v_dd_type = 'EXAM_GROUP';

            response = await axios.get(`url_fetch_data/32_url_param_exam_details_dropdown_fun/38/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
            /${p_st_class}
            /${v_stream}
            /${p_exam_term_name}
            /${p_exam_group_id}
            /${v_dd_type}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setexam_group_idItems(updatedata);           

            v_dd_type = 'TERM';

            response = await axios.get(`url_fetch_data/32_url_param_exam_details_dropdown_fun/38/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
            /${p_st_class}
            /${v_stream}
            /${p_exam_term_name}
            /${p_exam_group_id}
            /${v_dd_type}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setexam_term_nameItems(updatedata);

            v_dd_type = 'EXAM_NAME';

            response = await axios.get(`url_fetch_data/32_url_param_exam_details_dropdown_fun/38/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
            /${p_st_class}
            /${v_stream}
            /${p_exam_term_name}
            /${p_exam_group_id}
            /${v_dd_type}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}
            /${v_null_parameter}        
            `,
            { headers: { Authorization: `Bearer ${v_token}` } });
            updatedata = await response.data.v_out_data.v_data;
            setexam_term_categItems(updatedata);

        }
    }                  

    const getSavedDetailFunction = async () => {        

        setShowRemarksEntry(0);

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.info("Entry required for Class", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });            
            return;
        }
        
        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.info("Entry required for Class section", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });            
            return;
        }

        if (parseInt(ReduxGlobalData[0].stem) === 0){
            if(stream === undefined || stream.length === undefined || stream.length === 0){
                toast.info("Entry required for Class section", {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
                });            
                return;
            }            
        }

        if(exam_group_id === undefined || exam_group_id.length === undefined || exam_group_id.length === 0){
            toast.info("Entry required for Exam group", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });            
            return;
        } 

        if(exam_term_name === undefined || exam_term_name.length === undefined || exam_term_name.length === 0){
            toast.info("Entry required for Term", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });            
            return;            
        }

        if(exam_term_categ === '0' || exam_term_categ === undefined || exam_term_categ.length === undefined || exam_term_categ.length === 0){
            toast.info("Entry required for Exam type", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });
            return;
        }

        // console.log("exam_term_categ >> ", exam_term_categ)

        setdisableShow(true);
        setShowRemarksEntry(1);
        setshowNavBar(false);
        setdisableShow(false);
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;    

    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            
            // if(AllowUpdateTag){return;}
            return;
        }else if (value === 'BTN_NEW'){            
            // setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            // if (TableRecordID === 0) {
            //     toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
            //         hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
            //         draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            //     });
            //     return;
            // }

            // // console.log('BTN_PRINT TableRecordID >> ', TableRecordID)
            
            // const temp_data = {
            //     temptableid: TableRecordID,                
            //     back_url: 'Fc'
            // }
            
            // dispatch(setTempDataFun(temp_data));

            // // here we are printing the fee receipt

            // navigate("/FcprFkg");

        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            getSavedDetailFunction();
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();
        
        console.log("headingcolumn >> ", headingcolumn);

        console.log("Items >> ", items);

        // setp_till_month(6);

        // setItems(emptyListItems);

    }
    
    const tdData =() =>{

        if (parseInt(exam_group_id) > 0 && parseInt(showRemarksEntry) > 0){

            const data = {               

                board:ReduxGlobalData[0].brd,
                student_type:ReduxGlobalData[0].st,
                medium:ReduxGlobalData[0].medi,
                fee_categ:ReduxGlobalData[0].fcat,
                pay_term:ReduxGlobalData[0].ptm,

                st_class, 
                st_sec,
                stream,
                exam_group_id,
                exam_term_name,
                exam_term_categ,
                allow_new:userAuthData.allow_new   
            }

            // console.log("Data is >> ", data)

            // 601 = Final Result
            // 603 = REMARKS

            // shiv
            
            if (exam_group_id === '601'){
                return <ExamFinalMarks p_data = {data}/>  
            }else if (exam_group_id === '603'){
                return <ExamRemarks p_data = {data}/>            
            }else{                
                return <ExamMarks p_data = {data}/>
            }
            
        }  
            
    }
   
return (

<>    

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css h-[10vh]">
        <div className="form_div_inner_css grid grid-cols-10">

            <div className="col-span-1">
                <label className="label_inner_page_css required_css">Class</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 0)}
                    // autoFocus         
                    name="st_class"
                    id="st_class"
                    value={st_class}
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        ReduxDDStudentClassData[0]?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div className="col-span-1">
                <label className="label_inner_page_css required_css">Sec.</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 0)}
                    // autoFocus         
                    name="st_sec"
                    id="st_sec"
                    value={st_sec}
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        ReduxDDStudentSectionData[0]?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            {parseInt(ReduxGlobalData[0].stem) === 0 && (
                <div className="col-span-1">
                    <label className="label_inner_page_css required_css">Stream</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 0)}
                        // autoFocus         
                        name="stream"
                        id="stream"
                        value={stream}
                        onChange={handleInputValue}
                        onFocus={handleInputFocus}
                    >
                        <option></option>
                        {
                            ReduxDDStStreamData[0]?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}

            {/* && exam_group_idItems.length > 0 */}

            {(parseInt(st_class) > 0 ) && (
                <>
                <div className="col-span-3">
                    <label className="label_inner_page_css required_css">Exam Group</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 0)}
                        // autoFocus         
                        name="exam_group_id"
                        id="exam_group_id"
                        value={exam_group_id}
                        onChange={handleInputValue}
                        onFocus={handleInputFocus}
                    >
                        <option></option>
                        {
                            exam_group_idItems?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="col-span-1">
                    <label className="label_inner_page_css required_css">Term</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 0)}
                        // autoFocus         
                        name="exam_term_name"
                        id="exam_term_name"
                        value={exam_term_name}
                        onChange={handleInputValue}
                        onFocus={handleInputFocus}
                    >
                        <option></option>
                        {
                            exam_term_nameItems?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="col-span-1">
                    <label className="label_inner_page_css required_css">Exam Type</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 0)}
                        // autoFocus         
                        name="exam_term_categ"
                        id="exam_term_categ"
                        value={exam_term_categ}
                        onChange={handleInputValue}
                        onFocus={handleInputFocus}
                    >
                        <option></option>
                        {
                            exam_term_categItems?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
                
                </>
            )}

            <div className="mt-7">
                {(userAuthData.allow_read === 1 && !disableShow) && (
                    <button className="btn_find_css"
                        value = 'BTN_FIND'
                        onClick={handleButtonValue}>
                        Find
                    </button>
                )}
            </div>

        </div>        
        
    </div>

    {tdData()}


{
    showNavBar && (
        <nav className="btn_nav_inner_css">

            <div className="btn_div_main_css">       

                <div className="btn_div_inner_css">                                                  

                    <div>
                        <button className="btn_back_css"
                            value = 'BTN_BACK'
                            onClick={handleButtonValue}>
                            Back
                        </button>
                    </div>        
                        
                </div>
                
            </div>
        </nav>
    )
}

</form>
:null
}
</>
);};

export default StudentExamMarks;
